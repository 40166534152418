import env from './env'

export const LINK_URL_ROOT = '/'
export const LINK_URL_JOBS = '/jobs'
export const LINK_URL_SEARCH = '/search'
export const LINK_URL_SEARCH_VN = '/tim-kiem'
export const LINK_URL_COMPANIES = `/companies`
export const LINK_URL_LP_CV_ONLINE = '/cv-online'
export const LINK_URL_GROSS_NET = '/gross-net'
export const LINK_URL_CANDIDATES_CV = '/profile'
export const LINK_URL_REFERRAL = '/account/referral'
export const LINK_URL_REGISTER = '/employer/register'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_EMPLOYER = env.PUBLIC_EMPLOYER_APP_DOMAIN
export const LINK_URL_MY_JOBS_APPLIED = '/my-jobs/applied'
export const LINK_URL_MY_JOBS_SAVED = '/my-jobs/saved'
export const LINK_URL_CHAT_WITH_SUPPORT = '/chat-with-support'
export const LINK_URL_HELP_CENTER = `https://support.freec.asia/vi/`

export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_CANDIDATES_JOB_ALERT = '/account/job-alert'
export const LINK_URL_DOWNLOAD_APP = '/download-app'

export const LINK_URL_CANDIDATE_HOME = '/jobs'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_FOOTER_COMPANIES = `${env.PUBLIC_APP_DOMAIN}/companies`
export const LINK_URL_TERMS = env.PUBLIC_LINK_URL_TERMS || '/terms-of-service'
export const LINK_URL_POLICY = env.PUBLIC_LINK_URL_POLICY || '/privacy-policy'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'

export const LINK_FOOTER_JOBS = `${env.PUBLIC_APP_DOMAIN}/jobs`
export const LINK_URL_LP_HEADHUNT_SERVICE = '/employer/headhunt-service'
export const LINK_URL_FOOTER_LP_HEADHUNT_SERVICE = `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/headhunt-service`
export const LINK_URL_EMPLOYER_PRODUCT = '/employer/product'
export const LINK_URL_CANDIDATE_REGISTER = '/register'
export const LINK_URL_CANDIDATE = `${process.env.NEXT_PUBLIC_APP_DOMAIN}`

export const LINK_URL_CANDIDATES_MANAGE_YOUR_CV = '/account/manage-cv'
export const LINK_URL_CANDIDATES_MY_PROFILE = '/my-profile'
export const LINK_URL_CV = '/cv'
export const LINK_URL_CANDIDATES_EDIT_CV_ONLINE = '/profile/edit'
export const LINK_URL_EMPLOYER_CONTACT = `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/contact`
export const LINK_URL_FOOTER_LP_CV_ONLINE = `${env.PUBLIC_APP_DOMAIN}/cv-online`

export const LINK_URL_LINKIN = 'https://www.linkedin.com/company/freecasia/'
export const LINK_URL_FACEBOOK = 'https://www.facebook.com/freecasiacorp/'
export const LINK_URL_YOUTUBE = 'https://www.youtube.com/channel/UCIb1ZELs5mI5I2T5xTIB-zQ'
export const LINK_URL_INSTAGRAM = 'https://www.instagram.com/freec_vn/'

export const LINK_URL_BLOG_CATEGORY = 'https://blog.freec.asia/nha-tuyen-dung/'
export const LINK_URL_DOWNLOAD_APPLE = 'https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1'
export const LINK_URL_DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.freec.asia'

export const LINK_URL_EMPLOYER_REGISTER = `${env.PUBLIC_EMPLOYER_APP_DOMAIN}/register`
export const API_URL_CONTACT_REQUEST = '/api/v2/public/contact_request'
export const LINK_CV_ONLINE_CONTACT = '/cv-online?contact=true'
export const LINK_PATH_NAME_CV_ONLINE = '/page-cv-online'
